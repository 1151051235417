import styles from './Panel.module.scss';

import clsx from 'clsx';
import React from 'react';

export const Panel: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { restrictedWidth?: boolean }
> = (props) => {
  const { children, className, restrictedWidth, ...rest } = props;

  return (
    <div
      className={clsx(styles.panel, className, {
        [styles.restrictedWidth]: restrictedWidth,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
