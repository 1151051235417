import { Panel } from '../../../../../components/panel/Panel';

import { ClosingText } from './components/closing-text/ClosingText';
import { HeaderText } from './components/header-text/HeaderText';

export const TextTabPanel = () => {
  return (
    <Panel restrictedWidth>
      <HeaderText />
      <ClosingText />
    </Panel>
  );
};
