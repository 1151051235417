import styles from './GeneralTabPanel.module.scss';

import clsx from 'clsx';

import { Panel } from '../../../../../components';

import {
  BookingsWithAutoDisable,
  General,
  LinkPreDocuments,
  Permit,
  Receipt,
  VendorAndCurrency,
} from './components';

export function GeneralTabPanel() {
  return (
    <Panel restrictedWidth className={clsx(styles.panel)}>
      <General />
      <VendorAndCurrency />
      <Permit />
      <LinkPreDocuments />

      <Receipt />

      <BookingsWithAutoDisable />
    </Panel>
  );
}
