import styles from '../DocumentPreview.module.scss';

import {
  Delete,
  Download,
  Edit,
  Fullscreen,
  OpenInNew,
} from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';
import { ReactComponent as EditCopyIcon } from '@work4all/assets/icons/edit-pdf.svg';

import { usePermissions } from '@work4all/data';
import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';
import {
  downloadAuthed,
  parseNameAndExtension,
} from '@work4all/data/lib/utils';

import { useToolbarCustomActionsComponents } from '../../../../dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import { MIME_TYPES } from '../../../../preview/Preview';
import { ConvertPopover } from '../../../convert-popover/ConvertPopover';
import { Tooltip } from '../../../tooltip/Tooltip';
import { PreviewTitle } from '../../components';
import { PreviewTitleInput } from '../../components/preview-input';
import { IDocumentPreviewProps } from '../IDocumentPreviewProps';

import { DocumentEditablePreview } from './DocumentEditablePreview';

interface DocumentPreviewTitleProps extends IDocumentPreviewProps {
  setFullscreen: (value: boolean) => void;
  isLinkable: boolean;
}

export const DocumentPreviewTitle = (props: DocumentPreviewTitleProps) => {
  const {
    title,
    downloadUrl,
    filePath,
    mimeType,
    onCloseClick,
    onEditClicked,
    onDeleteClicked,
    onPdfEditClicked,
    fspUrl,
    exists,
    navigation,
    iconProps,
    onShareClicked,
    openInFullscreen,
    erpPreview,
    showFullscreenButton = true,
    customActions: custom,
    additionalTitleItems,
    convertProps,
    selectedRowsIdsList,
    entries = [],
    entity,
    entityData,
    setFullscreen,
    titleKeyField,
    isLinkable,
    mutate,
    onVisibilityToggle,
  } = props;

  const convertPopoverState = usePopoverState('bottom');

  const mimeTypeValues = Object.values(MIME_TYPES);

  const canPreview =
    showFullscreenButton && mimeTypeValues.includes(mimeType) ? true : false;

  useEffect(() => {
    if (openInFullscreen) {
      setFullscreen(true);
    }
  }, [openInFullscreen, setFullscreen]);

  const toolbar = useToolbarCustomActionsComponents({
    custom,
    skipMode: true,
    selectedRowsIdsList,
  });

  const { t } = useTranslation();
  const httpHeaders = useAuthHeaders();

  const { untypedPermissions } = usePermissions();

  const canEdit =
    entity && onEditClicked && untypedPermissions(entity).canEdit(entityData);
  const actions = (
    <>
      {additionalTitleItems}
      {onPdfEditClicked ? (
        <Tooltip title={t('COMMON.EDIT_COPY')}>
          <IconButton
            color="primary"
            onClick={onPdfEditClicked}
            className={styles['fileheader-icon']}
            size="small"
          >
            <EditCopyIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {toolbar.left}
      {onShareClicked && (
        <IconButton size="small" color="primary" onClick={onShareClicked}>
          <ShareIcon />
        </IconButton>
      )}
      {canEdit ? (
        <Tooltip title={t('COMMON.EDIT')}>
          <IconButton
            color="primary"
            onClick={onEditClicked}
            className={styles['fileheader-icon']}
            size="small"
          >
            <Edit />
          </IconButton>
        </Tooltip>
      ) : null}
      {onDeleteClicked ? (
        <Tooltip title={t('COMMON.DELETE')}>
          <IconButton
            color="primary"
            onClick={onDeleteClicked}
            className={styles['fileheader-icon']}
            size="small"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      ) : null}

      {convertProps ? (
        <IconButton
          size="small"
          color="primary"
          onClick={convertPopoverState.handleClick}
        >
          <ConvertIcon style={{ height: '1.5rem', width: '1.5rem' }} />
        </IconButton>
      ) : null}

      {canPreview && (
        <IconButton
          size="small"
          color="primary"
          onClick={() => setFullscreen(true)}
        >
          <Fullscreen />
        </IconButton>
      )}
      {!erpPreview ? (
        !canPreview &&
        exists && (
          <IconButton size="small" color="primary" href={fspUrl} download>
            <Download />
          </IconButton>
        )
      ) : downloadUrl ? (
        <IconButton
          color="primary"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            downloadAuthed(
              downloadUrl,
              `${isLinkable ? filePath : title}.${
                parseNameAndExtension(filePath).extension
              }`,
              httpHeaders
            );
          }}
        >
          <Download />
        </IconButton>
      ) : null}
      {iconProps?.showPreviewExternallyIcon !== false &&
      ((filePath && isLinkable) || fspUrl) ? (
        <IconButton
          color="primary"
          size="small"
          href={filePath && isLinkable ? filePath : fspUrl}
          target="_blank"
        >
          <OpenInNew />
        </IconButton>
      ) : null}
      {navigation ? navigation : null}
      {toolbar.right}
    </>
  );

  const previewTitle = entity ? (
    <DocumentEditablePreview
      entity={entity}
      entityData={entityData}
      entries={entries}
    >
      {({ onEdit, onPopoverClose }) => (
        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== title) {
              if (mutate) mutate({ [titleKeyField]: e.target.value });
              else onEdit({ [titleKeyField]: e.target.value });
            }
            onPopoverClose();
          }}
          value={title}
          onCloseClick={onCloseClick}
          accessor={titleKeyField}
          leftActions={actions}
          onVisibilityToggle={onVisibilityToggle}
        />
      )}
    </DocumentEditablePreview>
  ) : (
    <PreviewTitle
      onCloseClick={(e) => {
        setFullscreen(false);
        //eslint-disable-next-line
        //@ts-expect-error type not assignable to parameter 'MouseEvent<HTMLButtonElement, MouseEvent>'
        onCloseClick(e);
      }}
      onShareClicked={onShareClicked}
      actions={actions}
      onVisibilityToggle={onVisibilityToggle}
    >
      {title}
    </PreviewTitle>
  );

  return (
    <>
      {convertProps && (
        <ConvertPopover {...convertProps} popoverState={convertPopoverState} />
      )}
      {previewTitle}
    </>
  );
};
