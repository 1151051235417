import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IResponse } from '@work4all/data';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { CustomToolbarMappedAction } from '../../../dataDisplay/actions-bar/ToolbarTypes';
import {
  EntityPickerTabProps,
  ListEntityPicker,
} from '../components/list-entity-picker/ListEntityPicker';
import { SortedListItem } from '../components/SortedListItem';
import {
  PickerSettingsSortBy,
  usePickerSettings,
} from '../hooks/use-picker-settings';
import { IPickerProps } from '../types';

const CONST_ARRAY = [];
export type IArticlePickerProps<TMultiple extends boolean> = IPickerProps<
  Article,
  TMultiple
> & {
  functions?: CustomToolbarMappedAction[];
  size?: {
    width: number;
  };
  onSizeChange?: (input: { width: number }) => void;
};

export function ArticlePicker<TMultiple extends boolean>(
  props: IArticlePickerProps<TMultiple>
) {
  const {
    data,
    onChange,
    functions,
    prefilter = CONST_ARRAY,
    useSearchHistory = true,
    ...rest
  } = props;
  const { t } = useTranslation();
  const allFields = useMemo(() => {
    return { ...ARTICLE_FIELDS, ...data };
  }, [data]);

  const { sortedPickerEntities } = usePickerSettings(Entities.article);

  const [searchTerm, setSearchTerm] = useState('');

  const tabs = useMemo<EntityPickerTabProps<Article>[]>(() => {
    const transformResponse = (
      response: IResponse<Article>
    ): IResponse<Article> => {
      const responseData = response.data;
      if (searchTerm) {
        return {
          ...response,
          data: [...responseData],
        };
      }
      return response;
    };

    const result: EntityPickerTabProps<Article>[] = [
      {
        entity: Entities.article,
        data: allFields,
        label: t('COMMON.ARTICLE'),
        placeholder: t('SEARCH.ARTICLE_SEARCH_BY'),
        filterBy: ['number', 'name'],
        sortBy: sortedPickerEntities,
        useSearchHistory,
        prefilter,
        transformResponse,
        renderItemContent: (article) => (
          <SortedListItem
            sortedBy={sortedPickerEntities as PickerSettingsSortBy}
            name={article.name}
            number={article?.number}
            isInactive={article.isShutDown}
          />
        ),
        favorites: true,
      },
    ];

    if (functions?.length) {
      const erpKinds = functions
        .filter(
          (x) =>
            !searchTerm ||
            x.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((x) => ({ ...x, id: x.actionKey }));

      const value = Array.isArray(props.value)
        ? props.value
        : props.value
        ? [props.value]
        : [];
      const specialPositionsDataset = {
        total: erpKinds.length,
        loading: false,
        fetchMore: () => Promise.resolve(),
        refetch: () => Promise.resolve(null),
        data:
          searchTerm.trim().length === 0 ? [...erpKinds, ...value] : erpKinds,
      };

      result.push({
        label: t('COMMON.FUNCTIONS'),
        placeholder: t('SEARCH.SEARCH_FUNCTIONS'),
        fixedDataSet: specialPositionsDataset,
        renderItemContent: (input) => {
          const func = input as unknown as CustomToolbarMappedAction;
          return (
            <Typography
              variant="body2"
              noWrap
              sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              <func.IconComponent />
              {func.title}
            </Typography>
          );
        },
        favorites: false,
      } as EntityPickerTabProps<Article>);
    }

    return result;
  }, [
    allFields,
    t,
    sortedPickerEntities,
    useSearchHistory,
    prefilter,
    functions,
    searchTerm,
    props.value,
  ]);

  return (
    <ListEntityPicker
      tabs={tabs}
      onSearchValueChange={setSearchTerm}
      {...rest}
      onChange={(article) => {
        // @ts-expect-error TECH_DEBT
        if (article?.id in ErpPositionsKind) {
          // @ts-expect-error TECH_DEBT
          onChange(article?.id);
        } else {
          onChange(article);
        }
      }}
      layout="advanced"
    />
  );
}

const ARTICLE_FIELDS: Article = {
  id: null,
  number: null,
  name: null,
  isShutDown: null,
};
