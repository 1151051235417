import styles from './MoreTabPanel.module.scss';

import clsx from 'clsx';

import { Panel } from '../../../../../components';

import { Delivery, Payment } from './components';

export function MoreTabPanel() {
  return (
    <Panel className={clsx(styles.panel)} restrictedWidth>
      <Payment />
      <Delivery />
    </Panel>
  );
}
