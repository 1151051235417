import styles from './PositionPicker.module.scss';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Popover } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { Divider } from '../../../dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '../../../input/checkbox-radio-item';
import { FilterTextInput } from '../components';
import { HeaderRow } from '../components/custom-list-entity-picker/HeaderRow';
import { ItemRow } from '../components/custom-list-entity-picker/ItemRow';
import { NoResultsRow } from '../components/custom-list-entity-picker/NoResultsRow';
import { useKeyboardNavigation } from '../components/custom-list-entity-picker/useKeyboardNavigation';
import { toSelectionValue } from '../components/list-entity-picker/utils';
import { useSelectionModel } from '../hooks/use-selection-model';
import { IPickerProps } from '../types';

export interface IPositionPickerProps
  extends Pick<
    IPickerProps<Position, false>,
    'value' | 'onChange' | 'maxItems' | 'clearable' | 'placeholder'
  > {
  positions: Position[];
  descriptionField?: 'longtext' | 'shortText';
  onDescriptionFieldChange?: (value: 'longtext' | 'shortText') => void;
}

// This is a trimmed down version of ListEntityPicker with customizations.
export function PositionPicker(props: IPositionPickerProps) {
  const {
    value,
    onChange,
    maxItems = 5,
    clearable = true,
    placeholder,
    positions,
    onDescriptionFieldChange,
    descriptionField = 'longtext',
  } = props;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const initialSelected = useRef(toSelectionValue(value)).current;
  const selection = useSelectionModel<Position, false>({
    multiple: false,
    keyFn: (position) => position.id,
    initialSelected,
  });

  useEffect(() => {
    selection.setSelected(toSelectionValue(value));
  }, [selection, value]);

  const handleSelect = (value: Position) => {
    selection.select(value);
    onChange(selection.getSelected());
  };

  const handleDeselect = (value: Position) => {
    selection.deselect(value);
    onChange(selection.getSelected());
  };

  const handleToggle = (value: Position) => {
    if (selection.isSelected(value)) {
      if (clearable) {
        handleDeselect(value);
      }
    } else {
      handleSelect(value);
    }
    setQuery('');
    inputRef.current?.focus();
  };

  const [query, setQuery] = useState('');
  const queryTrimmed = query.trim();

  const items = useMemo(() => {
    let items = [...positions];

    if (queryTrimmed !== '') {
      const q = queryTrimmed.toLocaleLowerCase();

      items = items.filter((el) => {
        return (
          Boolean(
            [el.longtext, el.shortText].find((x) =>
              x.toLocaleLowerCase().includes(q)
            )
          ) || el.number.includes(q)
        );
      });
    }

    items.sort((a, b) => a.index - b.index);

    return items;
  }, [positions, queryTrimmed]);

  const isInteractive = useCallback(
    (item: Position) => SELECTABLE_POSITION_TYPES.includes(item.positionKind),
    []
  );

  const { onKeyDown, itemRefs, focusedIndex } = useKeyboardNavigation(
    positions,
    isInteractive,
    (selectedItem: Position) => onChange(selectedItem)
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusedIndex]);

  function renderRow(position: Position, index: number) {
    const hasSelection = !!selection.getSelected();
    const isSelected = selection.isSelected(position);
    const isHighlighted = index === focusedIndex;

    return (
      <ItemRow
        key={position.id}
        ref={(el) => (itemRefs.current[index] = el)}
        isInteractive={isInteractive(position)}
        hasSelection={hasSelection}
        isSelected={isSelected}
        title={
          position[descriptionField] || position.longtext || position.shortText
        }
        className={isHighlighted ? styles.highlight : ''}
        onClick={() => handleToggle(position)}
        onClear={() => onChange(null)}
        content={
          <>
            <span className={styles.mark} />
            <span className={styles.number}>{position.number}</span>
            <span className={styles.text}>
              {position[descriptionField] ||
                position.longtext ||
                position.shortText}
            </span>
            <span className={styles.amount}>
              {isInteractive ? position.amount : null}
            </span>
            <span className={styles.unit}>
              {isInteractive ? position.unit : null}
            </span>
          </>
        }
      />
    );
  }

  const popoverState = usePopoverState();
  const settingsBtnRef = useRef(null);

  return (
    <div className={styles.root}>
      <Popover {...popoverState} anchorEl={settingsBtnRef.current}>
        <Box p="0 1rem" minWidth="12rem">
          <Divider title={t(`INPUTS.DESCRIPTION`)} />
          <CheckboxRadioItem
            control="radio"
            label={t(`POSITIONS_PICKER.COLUMNS.longtext`)}
            checked={descriptionField === 'longtext'}
            onChange={() => onDescriptionFieldChange?.('longtext')}
          />
          <CheckboxRadioItem
            control="radio"
            label={t(`POSITIONS_PICKER.COLUMNS.shortText`)}
            checked={descriptionField === 'shortText'}
            onChange={() => onDescriptionFieldChange?.('shortText')}
          />
        </Box>
      </Popover>
      <div className={styles.search}>
        <Box flex={1}>
          <FilterTextInput
            ref={inputRef}
            autoFocus
            value={query}
            placeholder={placeholder ?? t('PICKER.SEARCH.DEFAULT')}
            onChange={setQuery}
            onKeyDown={onKeyDown}
          />
        </Box>
        {onDescriptionFieldChange && (
          <IconButton
            onClick={() => popoverState.handleClick()}
            ref={settingsBtnRef}
          >
            <MoreVert />
          </IconButton>
        )}
      </div>

      <div
        style={{
          height: LIST_ITEM_HEIGHT * maxItems,
          overflow: 'auto',
          flex: 'auto',
          isolation: 'isolate',
        }}
      >
        <HeaderRow
          hasSelection={!!selection.getSelected()}
          content={
            <>
              <span className={styles.mark} />
              <span className={styles.number}>
                {t('POSITIONS_PICKER.COLUMNS.NUMBER')}
              </span>
              <span className={styles.text}>
                {t(`POSITIONS_PICKER.COLUMNS.${descriptionField}`)}
              </span>
              <span className={styles.amount}>
                {t('POSITIONS_PICKER.COLUMNS.AMOUNT')}
              </span>
              <span className={styles.unit}>
                {t('POSITIONS_PICKER.COLUMNS.UNIT')}
              </span>
            </>
          }
        />
        {items.length === 0 && <NoResultsRow />}
        {items.map(renderRow)}
      </div>
    </div>
  );
}

const SELECTABLE_POSITION_TYPES: ErpPositionsKind[] = [
  ErpPositionsKind.STANDARD,
  ErpPositionsKind.STUECKLISTE,
  ErpPositionsKind.INTERNE_POSITION,
  ErpPositionsKind.INTERNE_STUECKLISTE,
];

const LIST_ITEM_HEIGHT = 50;
